.how_it_works_bg {
    background-color: aliceblue;
    border: 1px solid #eee;
    /* padding: 10px; */
    border-radius: 10px;
}

.how_it_works_font {
    font-size: 32px;
    font-weight: 400;
}

