.scrollbar::-webkit-scrollbar {
    width: 20px;
    height: 20px;
}

.scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #fff;
}

.scrollbar::-webkit-scrollbar-thumb {
    background: #ccc;
    ;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
}

.rental_brand {
    font-size: .6rem;
    font-weight: 500;
}

.partner_name {
    font-size: .6rem;
    font-weight: 500;
}

.subCat {
    font-size: .6rem;
    font-weight: 500;
}

.rental_location,
.rental_price {
    font-size: .6rem;
    font-weight: 500;
}