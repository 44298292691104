

html,body{
    font-family: "Roboto", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 1.2rem;
    line-height: .8rem;
    background-color: #f1f1f1;
}
h1, h2, h3, h4, h5, h6{
    font-family: "Roboto", sans-serif;
}
.font-roboto{
    font-family: "Roboto", sans-serif !important;
}
select{
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

nav ol li{
    font-optical-sizing: auto;
    font-weight: 400!important; 
    /* font-size: 1.2rem!important; */
}

.MuiBox-root button{
    
    font-optical-sizing: auto;
    font-weight: 400!important; 
    font-size: 16px;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded{
    min-height: 48px !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded{
    margin: 0px !important;
}
.css-o4b71y-MuiAccordionSummary-content.Mui-expanded{
    margin: 12px 0 !important;
    color: #1550A1;
}
.css-o4b71y-MuiAccordionSummary-content .panel-heading{
    color: #7B7B7B;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;


/* body h2{
    font-family: "Roboto Condensed", sans-serif!important;
    font-optical-sizing: auto!important;
    font-weight: 400!important;
    font-size: 1rem!important;
    
} */