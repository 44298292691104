// POPPINS
@font-face {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  src: url("../fonts/Roboto/Roboto-Light.ttf");
}
@font-face {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  src: url("../fonts/Roboto/Roboto-Regular.ttf");
}
@font-face {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  src: url("../fonts/Roboto/Roboto-Medium.ttf");
}
// @font-face {
//   font-family: "Roboto", sans-serif;
//   font-weight: 600;
//   src: url("../fonts/Roboto/Roboto-SemiBold.ttf");
// }
@font-face {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  src: url("../fonts/Roboto/Roboto-Bold.ttf");
}

//
html body {
  @apply font-body antialiased;
}

h1,
h2,
h3,
h4 {
  @apply font-display;
}

:root {
  --font-display: "Roboto", sans-serif;
  --font-body: "Roboto", sans-serif;
}

.theme-animals {
  --font-display: Inter;
  --font-body: Inter;
}
