.table_heigt_blog {

    height: 190px;
    width: 100%;
    /* border: 2px solid #eee; */
    box-shadow: 0 0 2px 2px #eee;
    border-radius: 3px;
    /* border-radius: 5px; */
    /* background-color: rgb(178, 213, 243); */
}

.first {
    width: 56%;
    /* text-align: justify; */
    padding: 5px;
}



/* .blog_img img {
    margin: auto 0;
    
    width: 280px;
    height: 170px;
    min-height: 170px;
    max-height: 170px!important;
    object-fit: cover;
} */

.first ul h4 a {}

.top_heading {
    padding: 10px 5px;
    border-radius: 5px;
    box-shadow: 0 0 3px 2px #eee;
}

.first .desc {
    font-size: 17px;
    color: rgb(135, 135, 135);
    line-height: 24px;
}

.blog_heading_text {
    font-family: "Roboto";
    line-height: 22px;

}

.blog_desc_text {
    font-family: "Roboto";
    font-size: 14px;
    color: #525252;
    line-height: 22px;
}


.try_border {
    background: rgb(255, 255, 255);
    border-radius: 5px;
}

@media (max-width:499px) {
    .blog_img img{
        width: 100%;
        height: 170px;
        min-height: 170px;
        object-fit: cover;
    }
    .first {
        width: 100%;
    }
    .table_heigt_blog{
        height: auto;
    }
}