body{
    scroll-behavior: smooth;
}
.text_blue {
    background-color: aqua;
}

.image_height {
    position: relative;
    max-height: 600px;
    height: 600px;
}

@media  (min-width:2560px) {
    .image_height {
        position: relative;
        max-height: 800px;
        height: 800px;
    }
}

@media  (max-width:1440px) {
    .image_height {
        position: relative;
        max-height: 450px;
        height: 450px;
    }
}

@media  (max-width:1024px) {
    .image_height {
        position: relative;
        max-height: 350px;
        height: 320px;
    }
}

.image_height .banner_img {
    width: 100%;
    object-fit: contain;
    height: auto;
}

/* ------------------------------------------------------------------ */
/* ------------------------------------------------------------------ */

/* buy sell  */
.font_s {
    display: block;
    font-size: .5rem;
    text-align: center;
    font-weight: 400;
    /* padding-top: 5px; */
}

.The_main {
    width: 100%;
}

.cus_height img {
    margin: 0 auto;
    width: 35%;
}

.cus_height {
    padding: 7px;
    min-height: 80px;
    /* background-color: #4271E6; */
}

.Explore_more_btn {
    display: block;
    width: 100%;
    padding: 10px 0;
    border-radius: 10px;
    text-transform: uppercase;
    color: rgb(0, 0, 0);
    background-color: #4271E6;
    color:#fff;
    font-weight: 400;
}

.The_main .one,
.two,
.three,
.four {
    width: 33.33%;
    display: flex;
    border: 1px solid #ccc;
}




@media (max-width:599px) {
    .image_height {
        /* min-height: 30vh;
        max-height: 30vh;
        padding: 10px 0; */
        /* object-fit: cover; */
        max-height: 130px;
        height: 130px;
        width: auto;

    }

    .prev,
    .next {
        top: 30%;
        /* display: none; */
    }

    .banner_img {
        /* object-fit:contain; */
        border-radius: 10px;
        height: 130px;

    }
}

.bannerSearch {
    /* position: absolute; */
    /* top:20%; */
    left: 10%;
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.container_banner_Search {
    width: 100%;
    border-radius: 10px;
    padding: 20px 1rem;
    background-color: rgb(255, 255, 255);
}

#HPselect,
#selectBrand,
#selectState {
    width: 100%;
    display: block;
}


.bloc-tabs {
    display: flex;
}

.tabs {
    padding: 15px;
    text-align: center;
    width: 50%;
    background: rgba(128, 128, 128, 0.075);
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.274);
    box-sizing: content-box;
    position: relative;
    outline: none;
}

.tabs:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs {
    background: white;
    border-bottom: 1px solid transparent;
}

.active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 5px;
    background: rgb(105, 155, 242);
}

.content-tabs {
    flex-grow: 1;
}

.content {
    background: white;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: none;
    transition: all .2s linear;
}

.active-content {
    display: block;
}

.Three_line_status {
    display: none;

}

.ctrl_banner_img{
    top:0px;
    left:0;
    bottom: 0;
    right: 0;
    height: 600px;
}

@media (max-width:599px) {
    .bannerSearch {
        max-width: 100%;
        display: none;
    }

    .container_banner_Search {
        padding: 5px 1rem;
        margin-top: 10px;
    }

    .Three_line_status {
        display: flex;
        top: 90%;
        display: none;
    }

    .content {
        padding: 0;
    }
}

