.for_border span {
    font-size: .6rem;
}

.float {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 40px;
    bottom: 40px;
    right: 40px;
    left: 30px;
    background-color: red;
    color: #FFF;
    border-radius: 20px;
    text-align: center;
    font-weight: 600;

}

