
 .sub_category_icons_text{
    font-size: .7rem;
    font-weight: 400;
    /* color: greenyellow; */
}

@media (max-width:499px) {
    .sub_category_icons_text{
        font-size: .7rem;
        line-height: .98;
    }
}