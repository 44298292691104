  .container1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
  }

  .left-section {
    width: 48%;
  }

  .right-section {
    width: 50%;
  }

  .shimmer-effect {
    height: 600px;
    background: linear-gradient(to right, #fafafa 0%, #fafafa 10%, #fafafa 100%);
    border-radius: 8px;
    padding: 20px;
    position: relative;
    overflow: hidden;
  }

  .shimmer-box {
    width: 100%;
    height: 45px;
    margin-bottom: 10px;
    background: linear-gradient(to right, #F5F5F5 16%, #F0F0F0 33%, #F5F5F5 43%);
    background-size: 1000px 100%;
    animation: shimmer 3s infinite;
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }


