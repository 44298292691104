/* .main_image img:hover{
 transform: scale(1.1);
 transition: all .3s linear;
 overflow:visible;
} */

.float2 {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 30px;
    left: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.my-float2 {
    margin-top: 16px;
}


.td_width1 {
    width: 350px;
    padding: 5px;
}

.td_width2 {
    width: 260px;
    padding: 5px;
}


.td_border {
    border-bottom: 1px solid #ccc;
}

.td2_border {
    border-top: 1px solid #ccc;
}

.css-19kzrtu {
    padding: 0 !important;
}

/* .newMainImageAni:hover > *{
  scale: 1.2;
} */

@media (max-width:499px) {
    .key_spec_font {
        font-size: .7rem;
        font-weight: 500;
    }

    .float2 {
        right: 2px;
        left: 15px;
        bottom: 21px;
    }

    /* .css-jpln7h-MuiTabs-scroller {
        overflow: scroll !important;
    }

    .css-1anid1y {
        overflow: scroll !important;
    }

    #tabs_scroll {
        overflow: scroll !important;
    }

    .MuiTabs-scroller {
        overflow: scroll !important;
    } */
}