.product_card_font {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    line-height: 22px;
    min-height: 44px;
}

.product_card_font_used{

    font-size: 16px;
    font-weight: 600;
    text-align: left;
    height: none;
    max-height: 55px;
}
.customPrimaryButton{
    margin: 0.5rem !important;
    border-radius: 0.375rem !important;
    width: calc(100% - 1rem);
    height: 45px;
    text-transform: capitalize !important;
    letter-spacing: normal !important;
    color: #1550A1 !important;
    border-color: #1550A1 !important;
}
.customEnquiryButton{
    margin: 0 !important;
    border-radius: 0.375rem !important;
    width: auto;
    height: 45px;
    text-transform: capitalize !important;
    letter-spacing: normal !important;
    color: #1550A1 !important;
    border-color: #1550A1 !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    background: #fff !important;
}
.cardImage{
    min-height: 210px;
    max-height: 210px;
}
.customGlideButtonPosition{
    position: absolute;
    top: calc(50% - 24px);
    z-index: 1;
    width: 100%;
}
.customGlideButtonPosition .nc-NextPrev button{
    position: absolute;
}
.customGlideButtonPosition .nc-NextPrev button[title="Prev"]{
    left: -25px;
}
.customGlideButtonPosition .nc-NextPrev button[title="Next"]{
    right: -25px;
}
.the_container {
    /* border: 2px solid #eee; */
    /* transition: .3s linear; */
}

/*.the_container:hover .product_img{
    transition: .3s linear;
   transform: scale(1.1);
} */

.enquir_now_btn {
    /* border: 1px solid lightblue; */
    display: block;
    /* background-color: lightblue; */
    background-color: #4271E6;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 8px 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    /* transition: .3s linear; */
    margin-top: 16px;
    color: #fff;
}

.enquir_now_btn:hover {
    color: #fff;
    background-color: rgb(66, 75, 246);
}

.price_range {
    font-size: 16px;
    font-weight: 200;
    text-align: center;

}

.product_img {
    /* background-color: #ccc; */
}

/* .produc_card_bacground {
    border: 2px solid #ccc;
    background: #fff;
    /* box-shadow: 0 0 35px 15px rgb(233, 226, 226); 
    border-radius: 2px;


} */

.asking_price{
    font-size: .8rem;
    font-weight: 500;
    color: black;
}
.model_year{
    font-size: .8rem;
    font-weight: 400;
    color: black;
}
.hmr{
    font-size: .7rem;
    font-weight: 400;
    color: black;
}
.Location{
    font-size: .7rem;
    font-weight: 400;
    color: black;
}

@media (max-width:599px) {
    .product_card_font {
        font-size: .7rem;
        font-weight: 500;
    }

    .produc_card_bacground {
        border: none;
        background: #fff;
        /* box-shadow: 0 0 35px 15px rgb(233, 226, 226); */
        border-radius: 2px;
    }

    .enquir_now_btn {
        font-size: 14px;
        font-weight: 500;
        background-color: #4271E6;
        color: #fff;
        border: 1px solid rgb(255, 255, 255);
        border-radius: 10px;
        font-weight: 400;
        padding: 4px 0;
    }

    .image_url_border {
        border: 1px solid rgba(0, 0, 0, .1);
    }
}