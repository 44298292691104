/* .desktop_navigation {
    font-size: 18px;
    font-weight: 500;
} */

.desktop_navigation>* {
    padding: 7px 10px;
    /* border-radius: 4px; */
}


.desktop_navigation>*:hover {
    
    color: #F17400;
    transition: .2s ease-out;
}

.search_bar_sticky_top {
    position: sticky;
    top: 0;

}

.login_reg_mobile {
    font-size: 16px;
    font-weight: 700;
    display: block;
}

#id_input_form {}

.search_input_desktop {}

.mobile_view_font {
    background: rgb(243, 243, 243);
    /* border-radius: 10px; */
    border: none;
    font-size: .6rem;
    font-weight: 200;
}

.mobile_view_font:focus {
    outline: none;
    border: none;
    box-shadow: none;
}
/* .buy_used_box_ani {
    --border-size: 3px;
    --border-angle: 0turn;
    background-image: conic-gradient(from var(--border-angle), rgb(255, 255, 255), rgb(255, 255, 255) 50%, rgb(249, 249, 249)), conic-gradient(from var(--border-angle), transparent 20%, rgb(23, 0, 227));
    background-size: calc(100% - (var(--border-size) * 1)) calc(100% - (var(--border-size) * 1)), cover;
    background-position: center center;
    background-repeat: no-repeat;
    animation: bg-spin 2s linear infinite;
} */

.autoCompletfontSize{
    font-size: 10px!important;
}

@keyframes bg-spin {
    to {
        --border-angle: 1turn;
    }
}

.box:hover {
    animation-play-state: paused;
}

@property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
}

@media (max-width:499px) {
    .login_reg_mobile {
        font-size: .7rem;
        /* display: none; */
    }

}


/* .header_smoth{
    transition: all 6s ease;
} */

.active_link{
    /* background-color:  rgb(52, 117, 246); */
    color:#F17400;
    border-radius: none;
}