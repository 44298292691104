.product-card {
    display: flex;
    align-items: center;
    border: 1px solid #F5F5F5;
    border-radius: 2px;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    width: 100%;    
    height: 280px;
  }
  
  .Enquire_now {
    width: 100%;
    height: 50px;
    border:1px solid #ccc;
    border-radius: 1px;
    z-index: 2;
  }
  
  .product_title{
    width: 100%;
    height: 50px;
    border:1px solid #ccc;
    z-index: 2;
  }
  .product-info {
    flex: 1;
  }
  
  /* Shimmer animation */
  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
  
  .product-card::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, #F5F5F5 16%, #F0F0F0 33%, #F5F5F5 43%);
    background-size: 1000px 100%;
    animation: shimmer 3s infinite;
  }
  